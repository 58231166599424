<template>
  <!-- 顶部导航栏 -->
  <div class="Index" ref="pageMain">
    <!-- <div class="header">
      <div class="logo_top">
        <img src="../assets/logo_blue.png" alt="" />
      </div>
      <div class="item_title">
        <div @click="gotoOther('http://www.jyrcfzjt.com/#/')">网站首页</div>
        <div
          @click="
            gotoOther('http://www.jyrcfzjt.com/#/scientificService/policy')
          "
        >
          科创服务
        </div>
        <div @click="gotoOther('http://baidu.com')">人才公寓</div>
        <div>招考招聘</div>
        <el-dropdown @command="command">
          <div>暨阳英才计划</div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a"
              >顶尖人才、领军人才扶持政策</el-dropdown-item
            >
            <el-dropdown-item command="b">青年人才扶持政策</el-dropdown-item>
            <el-dropdown-item command="c"
              >高技能、紧缺人才扶持政策</el-dropdown-item
            >
            <el-dropdown-item command="d">海外人才扶持政策</el-dropdown-item>
            <el-dropdown-item command="e">企业引才扶持政策</el-dropdown-item>
            <el-dropdown-item command="f">“澄心计划”扶持政策</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="login_box">
        <p class="userinfo" v-if="userinfo.nickname">
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ userinfo.nickname
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" @click.native="openTip">
              <el-dropdown-item>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </p>
        <div class="loginbtns" v-else>
          <div class="logins" @click="flag = 1">登录</div>
          <div class="logins" @click="flag = 2" style="border-right: 0">
            注册
          </div>
        </div>
      </div>
    </div> -->

    <!-- 轮播图 -->
    <div class="swiper">
      <!-- 轮播图 -->
      <el-carousel :interval="5000" arrow="always" :height="'448px'">
        <el-carousel-item v-for="(item, index) in swiperList" :key="index">
          <img :src="baseURL + item.img" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 首页显示内容 -->
    <template v-if="flag == 3">
      <div class="container bgn">
        <div class="container_signup" ref="container1">
          <div class="container_signup_hot">
            <h2>热门职位</h2>
            <div class="keyWord">
              <el-input
                v-model="keyWord"
                placeholder="搜索相关单位"
                @change="keyWordChange"
              ></el-input>
            </div>
          </div>
          <ul class="container_signup_list">
            <li v-for="(item, index) in zplist" :key="index">
              <el-row>
                <el-col>
                  <h3 class="signup_list_title">单位公开招聘</h3>
                </el-col>
              </el-row>
              <el-row>
                <img :src="item.logo" />
                <el-col>
                  <p>{{ item.company_name }}</p>
                  <p>{{ item.property_text }}</p>
                </el-col>

                <el-col>
                  <div
                    class="button cursor"
                    @click="
                      go(
                        `/recruitment-detail?id=${item.zhaopin_id}&company_id=${item.company_id}`
                      )
                    "
                  >
                    {{ item.status_text }}
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <p>招聘时间：{{ item.start_time }}~{{ item.end_time }}</p>
                </el-col>
              </el-row>
            </li>
          </ul>
          <el-row justify="end" v-if="zplist.length">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              @current-change="currentchange"
              @prev-click="prevclick"
              @next-click="nextclick"
              :page-size="12"
            />
          </el-row>
          <div v-else>暂无数据展示</div>
        </div>
        <!-- <div class="container_advantage" ref="container2">
          <h2>平台优势</h2>
          <div class="container_img">
            <div style="width: 466px; height: 369px">
              <img src="@/assets/images/upper left.png" />
            </div>
            <div style="width: 662px; height: 369px; margin-bottom: 21px">
              <img src="@/assets/images/upper right.png" />
            </div>
            <div style="width: 662px; height: 373px">
              <img src="@/assets/images/lower left.png" />
            </div>
            <div style="width: 466px; height: 373px">
              <img src="@/assets/images/lower right.png" />
            </div>
          </div>
        </div> -->
      </div>
      <div class="container_service" ref="container3">
        <!-- <div class="container_service_top">
          <ul>
            <li>
              <img src="@/assets/images/footer/1.png" />
              <span>报名需求分析，设置及确定报名流程</span>
            </li>
            <li>
              <img src="@/assets/images/footer/2.png" />
              <span>发布简章确定岗位报考条件</span>
            </li>
            <li>
              <img src="@/assets/images/footer/3.png" />
              <span>定义报名表格式及内容</span>
            </li>
            <li>
              <img src="@/assets/images/footer/4.png" />
              <span>全程短信通知技术支持</span>
            </li>
            <li>
              <img src="@/assets/images/footer/5.png" />
              <span>提供考场安排服务，定义准考证格式及内容</span>
            </li>
            <li>
              <img src="@/assets/images/footer/6.png" />
              <span>成绩发布服务，定义成绩单及内容</span>
            </li>
            <li>
              <img src="@/assets/images/footer/7.png" />
              <span>按批次安排面试，定义面试通知及内容；</span>
            </li>
            <li>
              <img src="@/assets/images/footer/8.png" />
              <span>按批次安排体检，定义体检通知及内容</span>
            </li>
          </ul>
        </div> -->
        <div class="container_service_bottom">
          <div class="bottom">
            <div class="bottom_text">
              <p>咨询热线：{{ footerdata.hotline }}</p>
              <p>工作时间：{{ footerdata.gongzsj }}</p>
              <p>{{ footerdata.bqsy }}</p>
            </div>
            <div class="bottom_text">
              <!-- <p>备案号信息：{{ footerdata.beianhao }}</p> -->
              <p class="beianA" @click="clickA">
                备案信息：苏ICP备2022030648号-1
              </p>
            </div>
            <div class="bottom_pub">
              <div>
                <div class="box">
                  <img :src="footerdata.gongzhonghao" alt="" />
                </div>
                <p>江阴人才集团公众号</p>
              </div>
              <div>
                <div class="box">
                  <img :src="footerdata.xiaocx" alt="" />
                </div>
                <p>“才聚江阴”协同荐才平台小程序</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- 登录开始-注册 -->
    <div class="container" v-if="flag == 2">
      <div class="login_info">
        <p>注册必读</p>
        <p>
          1、身份证号、真实姓名、手机和邮箱将引用到报名信息中，手机和邮箱将作为报考人员接收相关考试信息的工具，请务必保证身份证号，姓名，手机和邮箱信息完整，准确并和本人相符。
        </p>
        <p>2、相同的身份证号和姓名<span>只允许注册一次</span>。</p>
        <p>
          3、登录密码要妥善保管，如密码丢失，可使用系统提供方式找回密码。密码如被他人盗用责任自负。
        </p>
        <p>
          4、如果您的姓名中有生僻字打不出请用同音字代替，同音字用英文半角括号括起来，例子:张(一)，并且登录系统时姓名请输入
          张(一)。
        </p>
      </div>
      <!-- <ul class="login_type">
        <li>账号登录</li>
        <li>注册</li>
      </ul> -->
      <div class="login_form">
        <el-form
          ref="registerFormRef"
          :model="registerForm"
          :rules="rules"
          label-position="right"
          label-width="100px"
        >
          <el-form-item label="真实姓名：" prop="username">
            <el-input
              v-model="registerForm.username"
              placeholder="请填写真实姓名，注册后不能修改！"
            />
          </el-form-item>
          <el-form-item label="证件类型：" prop="id_type">
            <el-select v-model="registerForm.id_type">
              <el-option label="身份证" value="1" />
              <el-option label="护照" value="0" />
            </el-select>
          </el-form-item>
          <el-form-item label="证件号：" prop="id_no">
            <el-input
              v-model="registerForm.id_no"
              placeholder="请填写真实证件号，注册后不能修改！"
            />
          </el-form-item>
          <el-form-item label="邮箱：" prop="email">
            <el-input
              v-model="registerForm.email"
              placeholder="请填写真实邮箱！"
            />
          </el-form-item>
          <el-form-item label="密码：" prop="password">
            <el-input
              v-model="registerForm.password"
              type="password"
              placeholder="密码"
            />
          </el-form-item>
          <el-form-item label="确认密码：" prop="repassword">
            <el-input
              v-model="registerForm.repassword"
              placeholder="确认密码"
              type="password"
            />
          </el-form-item>
          <el-form-item label="手机号：" prop="mobile">
            <el-input
              v-model="registerForm.mobile"
              placeholder="请填写真实手机号！"
            />
          </el-form-item>
          <el-form-item style="margin-top: 50px">
            <el-button type="primary" @click="submitForm(registerFormRef)">
              注册
            </el-button>
          </el-form-item>
        </el-form>
        <!-- <div class="regest2" @click="flag = 1">登录</div> -->
      </div>
    </div>
    <div class="login" v-if="flag == 1">
      <div class="header-box">
        <div>账号密码登录</div>
        <div class="line"></div>
      </div>
      <div class="body-box">
        <el-form
          ref="loginFormRef"
          :model="loginform"
          :rules="loginRules"
          label-position="top"
        >
          <div class="top">
            <el-row :gutter="50">
              <el-col :span="12">
                <el-form-item label="真实姓名：" prop="account">
                  <el-input
                    v-model="loginform.account"
                    placeholder="请输入姓名"
                  />
                </el-form-item>
                <!-- <div class="form-item">
                  <div>真实姓名</div>
                  <el-input
                    v-model="loginform.account"
                    placeholder="请输入姓名"
                  ></el-input>
                </div> -->
              </el-col>
              <el-col :span="12">
                <el-form-item label="证件类型：" prop="id_type">
                  <el-select v-model="loginform.id_type">
                    <el-option label="身份证" value="1" />
                    <el-option label="护照" value="0" />
                  </el-select>
                </el-form-item>
                <!-- <div class="form-item">
                  <div>证件类型</div>
                  <el-select v-model="loginform.id_type">
                    <el-option label="身份证" value="1" />
                    <el-option label="护照" value="0" />
                  </el-select>
                </div> -->
              </el-col>
            </el-row>
          </div>
          <!-- <div class="item">
            <div class="form-item">
              <div>证件号</div>
              <el-input
                v-model="loginform.id_no"
                placeholder="请输入证件号"
              ></el-input>
            </div>
          </div> -->
          <div class="top top_">
            <el-form-item label="证件号" prop="id_no">
              <el-input v-model="loginform.id_no" placeholder="请输入证件号" />
            </el-form-item>
          </div>
          <div class="top top_">
            <el-form-item label="密码" prop="password">
              <el-input
                type="password"
                v-model="loginform.password"
                placeholder="请输入密码"
              />
            </el-form-item>
            <div class="lookPassword" @click="flag = 4">找回密码</div>
          </div>
          <div class="login-btn" @click="login">登录</div>
          <div class="regest" @click="flag = 2">注册</div>
        </el-form>
      </div>
    </div>
    <!-- 找回密码 -->
    <div class="login" v-if="flag == 4">
      <div class="header_forget">
        <div class="img_div">
          <div :class="[forgetFlag == 1 ? 'light_text' : 'grey_text']">
            确认账号
          </div>
          <img src="../assets/images/icon-3.png" alt="" />
        </div>
        <div class="img_div">
          <div :class="[forgetFlag == 2 ? 'light_text' : 'grey_text']">
            重置密码
          </div>
          <img src="../assets/images/icon-3.png" alt="" />
        </div>
        <div class="img_div">
          <div :class="[forgetFlag == 3 ? 'light_text' : 'grey_text']">
            重置成功
          </div>
        </div>
      </div>
      <div class="body-box">
        <el-form
          v-if="forgetFlag == 1"
          ref="forgetFormRef"
          :model="forgetform"
          :rules="forgetRules"
          label-position="top"
        >
          <div class="top_forget">
            <el-row>
              <el-col :span="24">
                <el-form-item label="真实姓名：" prop="account">
                  <el-input
                    v-model="forgetform.account"
                    placeholder="请输入姓名"
                  />
                </el-form-item>
                <el-form-item label="证件号" prop="id_no">
                  <el-input
                    v-model="forgetform.id_no"
                    placeholder="请输入证件号"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
        <el-form
          v-if="forgetFlag == 2"
          ref="forgetFormRef2"
          :model="forgetform2"
          :rules="forgetRules2"
          label-position="left"
        >
          <div class="top_forget">
            <el-row>
              <el-col :span="24">
                <el-form-item label="新密码" prop="password">
                  <el-input
                    type="password"
                    v-model="forgetform2.password"
                    placeholder="请输入新密码"
                  />
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmpassword">
                  <el-input
                    type="password"
                    v-model="forgetform2.confirmpassword"
                    placeholder="请再次输入新密码"
                  />
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                  <el-input
                    type="number"
                    v-model="forgetform2.mobile"
                    placeholder="请输入手机号"
                  />
                </el-form-item>
                <el-form-item label="验证码" prop="code">
                  <div class="box">
                    <input
                      class="box_input"
                      :class="[iserror ? 'box_input_red' : '']"
                      v-model="forgetform2.code"
                      placeholder="请输入短信验证码"
                      type="number"
                    />
                    <div class="btn" @click="send">{{ countDown }}</div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
        <div class="success_div" v-if="forgetFlag == 3">
          <div>重置密码成功！</div>
          <div>
            <span>{{ count }}</span
            >秒之后自动跳转登录页
          </div>
        </div>
        <div class="forget_btn" @click="submit">
          {{ forgetFlag == 3 ? "返回登录" : "确认" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getNotice,
  getSwiper,
  checkuserinfo,
  resetpwd,
  send,
  login,
  register,
  getZhaoping,
  indexconfig,
  gwlogin,
} from "@/api/index";

export default {
  components: {},
  name: "HomeView",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.registerForm.repassword !== "") {
          this.$refs.registerFormRef.validateField("repassword");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.registerForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var isCardId = (rule, value, callback) => {
      if (!value) {
        return new Error("请输入身份证号)");
      } else {
        if (this.registerForm.id_type == 1) {
          const reg =
            /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/;
          const card = reg.test(value);
          if (!card) {
            callback(new Error("身份证号格式有误！"));
          } else {
            callback();
          }
        }
      }
    };
    var forgetPass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.forgetform2.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var forgetCardId = (rule, value, callback) => {
      if (!value) {
        return new Error("请输入身份证号)");
      } else {
        const reg =
          /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/;
        const card = reg.test(value);
        if (!card) {
          callback(new Error("身份证号格式有误！"));
        } else {
          callback();
        }
      }
    };
    // 验证邮箱的规则
    var checkEmail = (rule, value, cb) => {
      // 验证邮箱的正则表达式
      const regEmail =
        /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;

      if (regEmail.test(value)) {
        // 合法的邮箱
        return cb();
      }

      cb(new Error("请输入合法的邮箱"));
    };
    // 验证手机号的规则
    var checkMobile = (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regMobile = /^[1][0-9]{10}$/;

      if (regMobile.test(value)) {
        return cb();
      }

      cb(new Error("请输入合法的手机号"));
    };
    return {
      user_id: "",
      back_time: null,
      last: 0,
      iserror: false,
      countDown: "发送验证码", // 倒计时
      count: 5,
      bVerification: false, // 节流
      forgetFlag: 1,
      footerdata: {},
      keyWord: "",
      current: 0,
      flag: 3,
      baseURL: "",
      username: "",
      swiperList: [],
      page: 1,
      per: 9,
      zplist: [],
      total: null,
      // 登录表单
      loginform: {
        account: "",
        id_type: "1",
        id_no: "",
        password: "",
      },
      //找回密码表单1
      forgetform: {
        account: "",
        id_no: "",
      },
      //找回密码表单2
      forgetform2: {
        password: "",
        confirmpassword: "",
        mobile: "",
        code: "",
      },
      forgetRules: {
        account: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
        ],
        id_no: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
          { validator: forgetCardId, trigger: "blur" },
        ],
      },
      forgetRules2: {
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        confirmpassword: [
          { required: true, message: "请再次输入新密码", trigger: "blur" },
          { validator: forgetPass2, trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkMobile, trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      loginRules: {
        account: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
        ],
        id_type: [
          { required: true, message: "请选择证件类型", trigger: "blur" },
        ],
        id_no: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
          { validator: isCardId, trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      // 注册表单数据
      registerForm: {
        username: "",
        password: "",
        repassword: "",
        email: "",
        mobile: "",
        id_type: "1", // 证件类型
        id_no: "", // 证件号
      },
      // 注册表单校验规则
      rules: {
        username: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
        ],
        id_type: [
          { required: true, message: "请选择证件类型", trigger: "blur" },
        ],
        id_no: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
          { validator: isCardId, trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { validator: checkEmail, trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        repassword: [
          { required: true, message: "请确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkMobile, trigger: "blur" },
        ],
      },
      timeout: "",
      userinfo: {},
      jsession: "",
    };
  },
  created() {
    // var url = "https://test-jyrczp.iguanwei.com/#/?jsession=1ef427e6-0926-448f-a5e6-3a7bd3e4563c";
    var url = window.location.href;
    var len = url.indexOf("jsession=");
    if (len > -1) {
      this.jsession = url.slice(len + 9);
    }
    if (!this.jsession) {
      this.jsession = localStorage.getItem("jsession");
    }
    if (this.jsession) {
      localStorage.setItem("jsession", this.jsession);
      this.newlogin();
    } else {
      this.$store.commit("changeState", "");
      localStorage.setItem("token", "");
      localStorage.setItem("user_info", "");
      this.userinfo = {};
      return;
    }
    // let userinfo = localStorage.getItem("user_info");
    // console.log(userinfo, "首页查看localStorage中userinfo");
    // if (userinfo.token) {
    //   this.userinfo = userinfo;
    //   // this.userinfo = JSON.parse(userinfo);
    // } else {
    //   this.userinfo = {};
    // }
    // console.log(this.userinfo, "首页查看this.userinfo");
  },
  beforeCreate() {},
  mounted() {
    // this.getnotice()
    // const token = localStorage.getItem('token')
    this.baseURL = localStorage.getItem("baseURL");
    this.getswiper();
    this.getzplist();
    this.indexconfig();
    if (this.$route.query.flag) {
      this.flag = this.$route.query.flag;
      if (this.flag != 3) {
        this.userinfo = {};
      }
    }
  },
  beforeDestroy() {},
  methods: {
    async newlogin() {
      await gwlogin({
        jsession: this.jsession,
      }).then((res) => {
        if (res.code == 1) {
          if (res.data == "" || res.data == "null") {
            this.$message({
              message: "登录异常，请重新登录",
              type: "error",
            });
            return;
          }
          this.$store.commit("changeState", res.data.userinfo.token);
          localStorage.setItem("token", res.data.userinfo.token);
          localStorage.setItem("user_info", JSON.stringify(res.data.userinfo));
          this.userinfo = res.data.userinfo;
        } else {
          this.$store.commit("changeState", "");
          localStorage.setItem("token", "");
          localStorage.setItem("user_info", "");
          this.userinfo = {};
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    command(command) {
      if (command == "a") {
        window.open("http://www.jyrcfzjt.com/#/policy/pdf1");
      } else if (command == "b") {
        window.open("http://www.jyrcfzjt.com/#/policy/pdf2");
      } else if (command == "c") {
        window.open("http://www.jyrcfzjt.com/#/policy/pdf3");
      } else if (command == "d") {
        window.open("http://www.jyrcfzjt.com/#/policy/pdf4");
      } else if (command == "e") {
        window.open("http://www.jyrcfzjt.com/#/policy/pdf5");
      } else if (command == "f") {
        window.open("http://www.jyrcfzjt.com/#/policy/pdf6");
      }
    },
    clickA() {
      window.open("https://beian.miit.gov.cn/");
    },
    gotoOther(url) {
      window.open(url);
    },
    //发送验证码
    async send() {
      if (!/^1[3-8]\d{9}$/.test(this.forgetform2.mobile))
        return this.$message({
          message: "请输入正确的手机号",
          type: "error",
          offset:200
        });
      if (this.bVerification) return;
      this.bVerification = true;
      await send({
        mobile: this.forgetform2.mobile,
        event: "resetpwd",
      })
        .then((res) => {
          if (res.code === 1) {
            this.$message({
              message:'发送验证码...',
              offset:200
            });
          } else {
            this.$message.error({
              message:res.msg,
              offset:200
            });
          }
        })
        .catch((error) => {
          this.$message.error({
            message:error.msg,
            offset:200
          });
        });

      let countDown = 59;
      const auth_time = setInterval(() => {
        this.countDown = countDown--;
        if (this.countDown <= 0) {
          this.bVerification = false;
          this.countDown = "发送验证码";
          clearInterval(auth_time);
        }
      }, 1000);
    },
    async indexconfig() {
      const res = await indexconfig();
      this.footerdata = res.data;
      localStorage.setItem("website_name", res.data.website_name);
    },
    keyWordChange() {
      this.getzplist();
    },
    openTip() {
      this.$confirm("此操作将退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          this.$store.commit("changeState", "");
          localStorage.clear();
          this.userinfo = {};
          this.$message({
            type: "success",
            message: "退出登录成功!",
          });
        })
        .catch(() => {});
    },
    go(path, status, status_text) {
      // this.$store.commit('changeState', '')
      // if (!localStorage.getItem("token")) {
      //   return this.$message({
      //     message: "请先登录再操作",
      //     offset: 300,
      //     type: "error",
      //   });
      // }
      this.$router.push({
        path,
      });
    },
    signup() {},
    currentchange(e) {
      this.page = e;
      this.getzplist();
    },
    prevclick() {
      this.page--;
      this.getzplist();
    },
    nextclick() {
      this.page++;
      this.getzplist();
    },
    async getzplist() {
      const res = await getZhaoping({
        page: this.page,
        per: this.per,
        search: this.keyWord,
      });
      this.total = res.data.total;
      this.zplist = [];
      this.zplist = res.data.list;
    },
    submitForm() {
      this.$refs.registerFormRef.validate(async (valid) => {
        if (valid) {
          // alert('submit!')
          const res = await register(this.registerForm)
            .then((res) => {
              if (res.code == 1) {
                this.flag = 1;
              } else {
                this.$message.error({
          message:res.msg,
          offset:200
        });
              }
            })
            .catch((error) => {
              this.$message.error({
                message:error.msg,
                offset:200
              });
            });
        } else {
          return false;
        }
      });
    },
    async getnotice() {
      const res = await getNotice();
    },
    async getswiper() {
      const res = await getSwiper();
      this.swiperList = res.data;
    },
    // 忘记密码
    submit() {
      if (this.forgetFlag == 1) {
        this.$refs.forgetFormRef.validate(async (valid) => {
          if (valid) {
            checkuserinfo({
              name: this.forgetform.account,
              no: this.forgetform.id_no,
            })
              .then((res) => {
                if (res.code == 1) {
                  this.user_id = res.data.id;
                  this.forgetform2.mobile = res.data.mobile;
                  this.forgetFlag = 2;
                } else {
                  this.$message({
                    message: res.msg,
                    type: "error",
                  });
                }
              })
              .catch((error) => {
                this.$message({
                  message: error.msg,
                  type: "error",
                });
              });
          } else {
            return false;
          }
        });
      }
      if (this.forgetFlag == 2) {
        this.$refs.forgetFormRef2.validate(async (valid) => {
          if (valid) {
            this.iserror = false;
            resetpwd({
              user_id: this.user_id,
              mobile: this.forgetform2.mobile,
              captcha: this.forgetform2.code,
              newpassword: this.forgetform2.password,
              event: "resetpwd",
            })
              .then((res) => {
                if (res.code == 1) {
                  this.$store.commit("changeState", res.data.userinfo.token);
                  localStorage.setItem("token", res.data.userinfo.token);
                  localStorage.setItem(
                    "user_info",
                    JSON.stringify(res.data.userinfo)
                  );
                  this.userinfo = res.data.userinfo;
                  this.forgetFlag = 3;
                  this.last++; //1
                  this.back_time = setInterval(() => {
                    this.count--;
                    if (this.count <= 0) {
                      this.flag = 3;
                      this.$message({
                        message: "跳转成功",
                        type: "success",
                      });
                      clearInterval(this.back_time);
                      this.count = 5;
                      this.forgetFlag = 1;
                    }
                  }, 1000);
                } else {
                  this.$message({
                    message: res.msg,
                    type: "error",
                  });
                }
              })
              .catch((error) => {
                this.$message({
                  message: error.msg,
                  type: "error",
                });
              });
          } else {
            this.iserror = true;
            this.last = 0;
            return false;
          }
        });
      }
      if (this.forgetFlag == 3) {
        this.last++; //2
        if (this.last % 2 == 0) {
          clearInterval(this.back_time);
          this.flag = 3;
          this.count = 5;
          this.$message({
            message: "重置密码成功！",
            type: "success",
          });
          this.forgetFlag = 1;
        }
      }
    },
    async login() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (valid) {
          const res = await login(this.loginform)
            .then((res) => {
              if (res.code == 1) {
                this.$store.commit("changeState", res.data.userinfo.token);
                localStorage.setItem("token", res.data.userinfo.token);
                localStorage.setItem(
                  "user_info",
                  JSON.stringify(res.data.userinfo)
                );
                this.userinfo = res.data.userinfo;
                this.flag = 3;
                this.$message({
                  message: "登录成功",
                  type: "success",
                  offset:200
                });
              } else {
                this.$message.error({
                  message:res.msg,
                  offset:200
                });
              }
            })
            .catch((error) => {
              this.$message.error({
                message:error.msg,
                offset:200
              });
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
.cursor {
  cursor: pointer;
}
.Index {
  width: 100%;
  min-height: 100vh;
  background-color: rgba(242, 246, 253);
  .header {
    padding: 20px 150px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .logo_top {
      width: 300px;
      img {
        width: 300px;
      }
    }
    .item_title {
      width: calc(100% - 500px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      /deep/.el-dropdown {
        color: #333;
      }
      div {
        font-weight: bold;
        font-size: 30px;
        cursor: pointer;
      }
      div:hover {
        color: #14337d;
      }
    }
    .login_box {
      width: 110px;
      /deep/.el-dropdown {
        font-size: 18px !important;
        font-weight: bold !important;
        color: #000 !important;
      }
    }
    .title {
      font-size: 24px;
      color: #1bb8b6;
      margin-left: 40px;
    }
    ul {
      display: flex;
      width: 500px;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      li {
        list-style: none;
        cursor: pointer;
        border-bottom: 4px solid transparent;
      }
    }
    .current {
      border-bottom: 4px solid #1ab7b6;
    }
    .loginbtns {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100px !important;

      .logins {
        margin: 0 !important;
        width: 100px !important;
        height: 24px !important;
        line-height: 24px !important;
        text-align: center !important;
        font-size: 20px !important;
        border-right: 2px solid #000;
        cursor: pointer;
        font-weight: bold !important;
      }
    }
    .userinfo {
      cursor: pointer;
      margin-left: auto;
      margin-right: 40px;
      font-size: 16px;
    }
  }
  .swiper {
    width: 100%;
    height: 448px;
    // margin-top: 60px;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
    .el-carousel {
      width: 100%;
      height: 448px;
      .el-carousel__container {
        width: 100%;
        height: 448px !important;
      }
    }
  }
  .login {
    position: relative;
    width: 764px;
    height: 612px;
    margin: -74px auto 0;
    background-color: #fff;
    border-radius: 8px;
    z-index: 999;
    .header_forget {
      width: 100%;
      height: 80px;
      border-bottom: 1px solid #d8d8d8;
      font-size: 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 40px;
      .img_div {
        width: 33%;
        display: flex;
        align-items: center;
        justify-content: center;
        .light_text {
          color: #0e226c;
        }
        .grey_text {
          color: #cdcece;
        }
        img {
          width: 15px;
          height: 15px;
          margin-left: 30px;
        }
      }
    }
    .top_forget {
      display: flex;
      justify-content: center;
      /deep/.el-input {
        width: 400px !important;
      }
      .box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        .box_input {
          border: 1px solid #dcdfe6;
          border-radius: 5px;
          height: 40px;
          padding-left: 10px;
          width: 210px;
        }
        .box_input_red {
          border-color: #f56c6c;
        }
        .box_input::-webkit-input-placeholder {
          /* WebKit browsers */
          color: rgb(199, 202, 209);
        }

        .box_input:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: rgb(199, 202, 209);
        }

        .box_input::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: rgb(199, 202, 209);
        }

        .box_input:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: rgb(199, 202, 209);
        }
        .btn {
          margin-left: 10px;
          border-radius: 5px;
          border: 1px solid #0e226c;
          color: #0e226c;
          width: 140px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .success_div {
      width: 100%;
      padding-top: 50px;
      div {
        width: 100%;
        padding-bottom: 30px;
        font-size: 14px;
        color: #333;
        text-align: center;
        span {
          color: #1ec8c6;
        }
      }
    }
    .forget_btn {
      width: 400px !important;
      margin: 20px 0 37px;
      margin-left: calc(50% - 200px);
      height: 60px;
      border-radius: 60px;
      line-height: 60px;
      background-color: #0e226c;
      color: #fff;
      font-size: 24px;
      text-align: center;
      cursor: pointer;
    }
    /deep/.el-form-item__content {
      margin-bottom: 10px !important;
    }
    .header-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 80px;
      box-sizing: border-box;
      padding-left: 64px;
      padding-top: 32px;
      border-bottom: 1px solid #d8d8d8;
      text-align: left;
      font-size: 26px;
      color: #333;
    }
    .line {
      width: 156px;
      height: 4px;
      border-radius: 4px;
      background-color: #0e226c;
    }
    .body-box {
      padding: 36px 64px 0;
      /deep/ .el-form-item__label {
        float: left;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        padding: 0;
      }
      /deep/.el-form-item {
        margin-bottom: 15px !important;
      }
      .top {
        display: flex;
        // align-items: center;
        .lookPassword {
          margin: 45px 0 0 30px;
        }
      }
      .top_ {
        .el-input {
          width: 466px;
        }
      }
      .form-item {
        text-align: left;
        span {
          margin-left: 34px;
          font-size: 16px;
          color: #333;
        }
      }
      .el-input {
        margin-top: 10px;
        width: 318px;
      }
      .el-select {
        margin-top: 15px;
        width: 268px;
      }
      .item {
        .el-input {
          width: 466px;
        }
      }
    }
    .login-btn {
      margin: 46px 0 37px;
      height: 60px;
      border-radius: 60px;
      line-height: 60px;
      background-color: #0e226c;
      color: #fff;
      font-size: 24px;
      text-align: center;
      cursor: pointer;
    }
  }
  .regest {
    width: 100%;
    color: #0e226c;
    font-size: 24px;
    text-align: center;
    cursor: pointer;
  }
  .regest2 {
    width: 466px;
    color: #0e226c;
    font-size: 24px;
    text-align: center;
    cursor: pointer;
    margin-left: 100px;
  }
  .bgn {
    background-color: rgba(242, 246, 253) !important;
  }
  .container {
    width: 1152px;
    padding-bottom: 50px;
    margin: 0 auto;
    background-color: #fff;

    .el-row {
      display: flex;
      justify-content: right;
    }
    .container_signup {
      .container_signup_hot {
        display: flex;
        margin-top: 48px;
        margin-bottom: 40px;
        h2 {
          width: 100%;
          display: flex;
          justify-content: left;
          color: #02307c;
        }
        .keyWord {
          width: 368px;
          height: 40px;
          /deep/ .el-input__inner {
            background: #ededed;
            border-radius: 20px;
          }
        }
      }
      .container_signup_list {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 41px;
        li {
          box-sizing: border-box;
          // margin-bottom: 10px;
          margin: 0 8px 10px;
          padding: 13px 20px 11px 24px;
          width: 368px;
          height: 160px;
          background: #fff;
          position: relative;
          text-align: left;
        }
        .button {
          width: 118px;
          height: 31px;
          line-height: 31px;
          border-radius: 31px;
          text-align: center;
          background-color: #f35403;
          font-size: 14px;
          color: #fff;
          margin-left: 12px;
        }
        .signup_list_title {
          font-size: 20px;
          color: #333;
          margin-bottom: 19px;
        }
        .el-button {
          position: absolute;
          top: 0;
          right: 0;
          width: 118px;
          height: 31px;
          border-radius: 44px;
          background: #13b5b4;
          font-size: 14px;
          color: #fff;
        }
        .el-row {
          flex-wrap: nowrap;
        }
        img {
          width: 54px;
          height: 54px;
          margin-right: 10px;
          margin-bottom: 14px;
        }
        p {
          font-size: 14px;
          color: #777;
        }
      }
    }
    .el-pagination {
      margin-bottom: 20px;
    }
    .container_advantage {
      margin-top: 41px;
      h2 {
        font-size: 24px;
        font-weight: 550;
        line-height: 42px;
        color: #02307c;
        margin-bottom: 51px;
      }
      .container_img {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
    .login_info {
      background-color: #0e226c;
      padding: 20px 98px;
      p {
        font-size: 14px;
        line-height: 24px;
        text-align: left;
        color: #fff;
        &:first-child {
          margin-bottom: 9px;
          font-size: 26px;
          font-weight: 500;
          line-height: 36px;
        }
        span {
          color: #2fc1d0;
        }
      }
    }
    .login_type {
      margin: 0px auto;
      width: 700px;
      display: flex;
      li:nth-child(2) {
        margin-left: 340px;
      }
    }
    .login_form {
      // width: 500px;
      margin: 40px 391px 0 201px;
      /deep/.el-input {
        width: 467px !important;
        height: 40px;
      }
      /deep/.el-form-item {
        margin-bottom: 25px !important;
      }
      /deep/.el-form-item__label {
        text-align: left;
      }
      .el-select {
        width: 196px;
        height: 40px;
        float: left;
      }
      .el-button {
        width: 466px;
        height: 60px;
        background: #0e226c;
        border-radius: 305px 305px 305px 305px;
        font-size: 26px;
        color: #fff;
      }
    }
  }
  .container_service {
    width: 100%;
    // height: 662px;
    margin: 0 auto;
    // margin-top: 150px;
    .container_service_top {
      width: 100%;
      height: 662px;
      background-image: url("@/assets/images/footer/底部-底图@2x.png");
      background-size: 100% 100%;
      padding-top: 71px;
      ul {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        width: 1152px;
        margin: 0 auto;
        li {
          width: 180px;
          margin-right: 120px;
          text-align: center;
          display: table-cell;
          img {
            margin-bottom: 19px;
            width: 100%;
            height: 100%;
            display: block;
          }
          span {
            font-size: 18px;
            color: #fff;
            line-height: 26px;
          }
        }
        li:nth-child(4n) {
          margin-right: 0;
        }
        li:nth-child(3),
        li:nth-child(4) {
          margin-top: -20px;
        }
        li:nth-child(n + 5) {
          margin-top: 36px;
        }
      }
    }
    .container_service_bottom {
      width: 100%;
      min-height: 260px;
      background-color: rgb(228, 232, 230);
      .bottom {
        box-sizing: border-box;
        width: 1152px;
        padding: 50px 0px 0 0px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        .bottom_pub {
          display: flex;
          div {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            .box {
              margin: 0 !important;
              width: 130px;
              height: 130px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            p {
              width: 181px;
              word-wrap: break-word;
            }
          }

          & div:first-child {
            margin-right: 62px;
          }
          & div:nth-child(2) p {
            display: table-cell;
            text-align: center;
          }
        }

        .bottom_text {
          p {
            text-align: left;
            margin-bottom: 20px;
          }
          .beianA {
            cursor: pointer;
          }
        }
      }
      .box {
        width: 132px;
        height: 130px;
        background: #d8d8d8;
      }
      p {
        font-size: 18px;
        color: rgb(74, 74, 74);
        line-height: 26px;
      }
    }
  }
}
</style>
